<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6 my-auto>
        <h1>Usuarios</h1>
      </v-flex>
      <v-flex xs12 md3 class="text-center d-none d-md-block">
        <h3>Nº Operadores</h3>
        <v-chip>{{ integrator.operators.length }}</v-chip>
      </v-flex>
      <v-flex xs6 md3 class="text-center d-none d-md-block">
        <h3>Usuarios registrados</h3>
        <v-chip>{{ integrator.numUsers }}</v-chip>
      </v-flex>
    </v-layout>

    <v-layout row wrap mt-4>
      <v-flex xs12 md6>
        <v-card elevation="1" class="mr-2">
          <v-data-table
            :headers="headers"
            :items="users"
            item-key="userId"
            :items-per-page="10"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
            @click:row="selectedItem"
            class="elevation-1 pa-1"
            locale="es-ES"
            disable-sort
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.token }}</td>
                <td>{{ item.email.substring(0, 6) + "****" }}</td>
                <td>{{ item.registeredOn }}</td>
              </tr>
            </template>
            <template v-slot:top>
              <v-text-field v-model="search" label="Buscar" class="mx-4">
                <v-icon slot="append" @click="searchUsers" title="Ver clave"> search</v-icon>
              </v-text-field>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>

      <v-flex xs12 md6>
        <v-card elevation="1">
          <v-simple-table dense loading>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Medios de acceso del usuario</v-toolbar-title>
              </v-toolbar>
            </template>

            <template>
              <thead>
                <tr>
                  <th>Operador</th>
                  <th>Medio de acceso</th>
                  <th>Fecha Inicio</th>
                  <th>Fecha Fin</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in mediaAccess" :key="item.mediaAccessId">
                  <td>{{ item.operator }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.start }}</td>
                  <td>{{ item.end }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Users",
  data: () => ({
    search: "",
    headers: [
      { text: "UserId", value: "userId" },
      { text: "Email", value: "email" },
      { text: "Fecha registro", value: "registeredOn" },
    ],
    users: [],
    totalItems: 0,
    options: {},
    mediaAccess: [],
  }),

  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    integrator() {
      return this.$store.getters.getIntegrator;
    },
  },
  watch: {
    options: {
      handler() {
        this.getUsersFromApi();
      },
      deep: true,
    },
  },
  mounted() {
    this.getUsersFromApi();
  },
  methods: {
    selectedItem: function(item) {
      this.$store.dispatch("fetchUserMediaAccess", item.userId).then(data => {
        this.mediaAccess = data;
      });
    },
    getUsersFromApi() {
      this.options.search = this.search;
      this.$store.dispatch("fetchUsers", this.options, this.search).then(data => {
        this.users = data.users;
        this.totalItems = data.count;
      });
    },
    searchUsers() {
      this.getUsersFromApi();
    },
  },
};
</script>

<style scoped></style>
